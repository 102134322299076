import * as React from 'react';
import useLiveData from 'hooks/useLiveData';
import AlertBanner from 'components/alertBanner';

const Alert = (props) => {
	const { response, status} = useLiveData({
		namespace: 'Alerts',
		model: 'Alert',
		method: 'all',
	});
	if (status !== useLiveData.LOADED)return null;
	if (response.data.length === 0)return null;
	if (typeof window === 'undefined')return null;
	const fullExpression = /^(?<delim1>\S)(?<pattern>.*?)(?<delim2>\S)(?<flags>i|g|m)?$/;
	let alerts = [...response.data].filter((alert) => {
		if (typeof window !== 'undefined' && parseInt(window.localStorage.getItem(`alert_${alert.id}`)) === 0)return false;
		if (!!alert.urls === false)return true; // If there are no URL patterns defined, accept the alert.
		const patterns = alert.urls.split(/\n/);
		for (const pattern of patterns) {
			if (pattern === window.location.pathname)return true; // If the URL pattern matches the pathname exactly, accept the alert.
			const result = fullExpression.exec(pattern);
			if (result === null)return false; // If the URL pattern is not really a RegExp, bail.
			let regex = null;
			if (result.groups.delim1 === result.groups.delim2)regex = new RegExp(result.groups.pattern, result.groups.flags);
			else regex = new RegExp(pattern);
			if (regex.test(window.location.pathname))return true; // If URL pattern is a RegExp, and matches, accept the alert.
		}
		return false;
	});
	if (alerts.length === 0)return null;
	return (
		alerts.map(alert => (
			<AlertBanner key={alert.id} {...alert} />
		))
	)
}

export default Alert;