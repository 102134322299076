import * as React from 'react';

import QuickLinks from 'components/quickLinks';
import useLiveData from 'hooks/useLiveData';
import { useStaticQuery, graphql } from 'gatsby';

const QuickLinkProvider = (props) => {
	const data = useStaticQuery(graphql`
		query quickLink {
		  allSmartTagsQuickLink {
		    edges {
		      node {
		        id
		        header
		        links {
		        	title
		        	description
		        	icon
			        buttonLink
			        buttonText
		        }
		        background
		        flush
		      }
		    }
		  }
		}
	`);
	const {response} = useLiveData({
		namespace: 'SmartTags',
		model: 'QuickLink',
		method: 'all',
		staticData: data,
		graphqlKey: 'allSmartTagsQuickLink',
	});
	if (response.data.length === 0)return null;
	let attr;
	for (let item of response.data) {
		if (item.id === props.id)attr = item;
	}
	if (!attr)return null;
	return <QuickLinks {...attr} />
}

export default QuickLinkProvider;