import { createTheme } from '@mui/material/styles';

const siteColors = {
	blue: {
		300: '#59A3D1',
		500: '#00558A',
		700: '#122449',
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			phone: 0,
			tablet: 640,
			laptop: 1024,
			desktop: 1200,
		},
	},
	typography: {
		htmlFontSize: 10,
		fontSize: 16,
		fontFamily: [
			'EB Garamond',
			'serif',
		].join(', '),
		h1: {
			fontSize: '3.6rem',
			lineHeight: 1.22,
			fontWeight: 600,
			'@media (min-width: 64rem)': {
				fontSize: '4.8rem',
			},
		},
		nav: {
			fontSize: '1.8rem',
			color: siteColors.blue[500],
		}
	},
	palette: {
		primary: {
			main: siteColors.blue[500],
			dark: siteColors.blue[700],
			light: siteColors.blue[300],
		},
		secondary: {
			main: '#F26E62',
		},
		background: {
			default: null,
		},
		text: {
			primary: null,
		},
	},
	components: {
		MuiGrid2: {
			defaultProps: {
				spacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				},
				columnSpacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				},
				rowSpacing: {
					phone: 1,
					tablet: 2,
					laptop: 4,
				}
			}
		},
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},	
	},
});

export default theme;