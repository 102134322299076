module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5GJXV9SW","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Forth Insurance","background_color":"#fff","theme_color":"#fff","icon":"src/images/icons/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bca7d2a28d3be6385d890d09294ec64d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-zms/plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"baseURL":"https://www.forthinsurance.com","models":["Content.Page","Content.Menu","Masthead.Slide","Masthead.Category","SmartTags.ImageCard","SmartTags.QuickLink","SmartTags.Gallery","SmartTags.ContentCard","Faq.Category","Faq.Question","Staff.Office"],"pages":[{"type":"ContentPage","component":"/opt/atlassian/pipelines/agent/build/src/templates/ContentPage.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
