import * as React from "react"
import { register } from 'swiper/element/bundle';
import { useMediaQuery } from '@mui/material';

register();

export const Slider = React.forwardRef((props, ref) => {
	const matches = useMediaQuery('(max-width: 640px)');
	let slides = (matches ? 1 : parseInt(props.slides)||1.5);

	// React.useEffect(() => {
	// 	// listen for Swiper events using addEventListener
	// 	swiperElRef.current.addEventListener('progress', (e) => {
	// 		const [swiper, progress] = e.detail;
	// 		console.log(progress);
	// 	});

	// 	swiperElRef.current.addEventListener('slidechange', (e) => {
	// 		console.log('slide changed');
	// 	});
	// }, []);

	if (slides === 1)slides = 'auto';

	return (
		<div>
			<swiper-container ref={ref} slides-per-view={slides} space-between="24" auto-height="false" navigation={props.navigation}>
				{props.children}
			</swiper-container>
		</div>
	);
});

export default Slider;