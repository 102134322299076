import * as React from 'react';

import { Modal, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import rcfLogo from 'images/rcf_logo.png';
import lincolnLogo from 'images/lincoln_agency_logo.png';
import pulleyWhiteLogo from 'images/pulley_white_logo.png';
import thomasFarrLogo from 'images/thomas_farr_logo.png';

import logo from 'images/logo-white.png';
import arrow from 'images/arrow.png';
import close from 'images/xmark.svg';

const logos = {
	rcf: rcfLogo,
	thomasfarr: thomasFarrLogo,
	lincoln: lincolnLogo,
	pulleywhite: pulleyWhiteLogo,
};

const RedirectModal = (props) => {
	const [showModal, setShowModal] = React.useState(false);
	const [activeLogo, setActiveLogo] = React.useState('');


	React.useEffect(() => {
		if (document !== undefined) {
			const search = document.location.search.substring(document.location.search.indexOf('?')+1);
			const query = {};
			search.split('&').forEach(item => {
				let pair = item.split('=');
				query[pair.shift()] = pair.join('=');
			});
			if (query.utm_campaign === 'namechange_redirect' && logos[query.utm_source] !== undefined) {
				setActiveLogo(query.utm_source);
				setShowModal(true);
			}
		}
	}, [setShowModal, setActiveLogo]);

	const handleClose = () => setShowModal(false);

	return (
		<Modal open={showModal} onClick={handleClose} aria-labelledby="redirect-title">
			<section className="redirect">
				<Container maxWidth="desktop">
					<Grid container spacing={0}>
						<Grid phone={12} laptop={6}>
							<div className="previousAgency">
								<div className="logo-container">
									<img className={`previousLogo ${activeLogo}`} src={logos[activeLogo]} alt="" />
									<p>Has changed<br/>our name to</p>
								</div>
								<div className="arrow">
									<img src={arrow} alt="" />
								</div>
							</div>
						</Grid>
						<Grid phone={12} laptop={6}>
							<div className="forthAgency">
								<button className="close" onClick={handleClose}><img src={close} alt="Close"/></button>
								<img src={logo} className="forthLogo" alt="" />
								<p id="redirect-title">You have been redirected to ForthInsurance.com</p>
							</div>
						</Grid>
					</Grid>
				</Container>
			</section>
		</Modal>
	)
}

export default RedirectModal;