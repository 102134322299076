import * as React from 'react';

import ImageCard from 'components/imageCard';
import useLiveData from 'hooks/useLiveData';
import { useStaticQuery, graphql } from 'gatsby';

const ImageCardProvider = (props) => {
	const data = useStaticQuery(graphql`
		query imageCard {
		  allSmartTagsImageCard {
		    edges {
		      node {
		        id
		        title
		        subtitle
		        body
		        direction
		        image
		        caption
		        vimeoID
		        buttonLink
		        buttonText
		        background
		        flush
		      }
		    }
		  }
		}
	`);
	const {response} = useLiveData({
		namespace: 'SmartTags',
		model: 'ImageCard',
		method: 'all',
		staticData: data,
		graphqlKey: 'allSmartTagsImageCard',
	});
	if (response.data.length === 0)return null;
	let attr;
	for (let item of response.data) {
		if (item.id === props.id)attr = item;
	}
	if (!attr)return null;
	return <ImageCard {...attr} />
}

export default ImageCardProvider;