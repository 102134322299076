import * as React from 'react';
import { Menu } from '@mui/material';

import Link from 'components/link';

const Dropdown = ({open, links, anchor, onClose, anchorOrigin, transformOrigin}) => {
	const overrides = {
		'& .MuiPopover-paper': {
			'boxShadow': 'none',
			'borderRadius': 0,
		},
		'& .MuiMenu-list': {
			'padding': 0,
		},
	};
	return (
		<Menu sx={overrides} anchorEl={anchor} open={open} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
			<div className="dropdown">
				{links.map((item, index) => <Link onClick={onClose} key={index} {...item} />)}
			</div>
		</Menu>
	)
}

export default Dropdown;