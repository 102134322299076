import * as React from "react"
import parse from 'html-react-parser';
import { Container, useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/link';
import { Script } from 'gatsby';

const ImageCard = ({image, caption = '', vimeoID, title, subtitle, body, buttonText = 'Learn More', buttonLink, children, direction = 'row', background, flush = false}) => {
	let content;
	if (body)content = parse(body);
	else content = children;

	const phone = useMediaQuery((theme) => theme.breakpoints.only('phone'));
	if (phone)direction = 'column-reverse';
	const vimeoIDRE = /https?:\/\/vimeo\.com\/(\d+?)\/?$/i;
	let videoID = vimeoID;
	if (vimeoIDRE.test(vimeoID))videoID = vimeoID.replace(vimeoIDRE, '$1');
	return (
		<section className={`imageCard ${background ? background : ''} ${flush ? 'flush' : ''}`}>
			<Container maxWidth="desktop">
				<Grid container direction={direction} alignItems="center">
					<Grid phone={12} tablet={6}>
						<div className={`imageContainer ${!!caption !== false && image ? 'has-caption' : ''}`} data-caption={caption}>
							{ image && <img src={image} alt={caption||''} /> }
							{ videoID && <>
								<div style={{padding:'52.73% 0 0 0', position:'relative'}}><iframe src={`https://player.vimeo.com/video/${videoID}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`} frameBorder="0" style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} allow="autoplay; fullscreen; picture-in-picture" title={caption}></iframe></div>
								<Script src="https://player.vimeo.com/api/player.js" />
							</> }
						</div>
					</Grid>
					<Grid phone={12} tablet={6}>
						<div className="content">
							<h2>{title}</h2>
							<h3>{subtitle}</h3>
							{content}
							{ buttonLink && <Link href={buttonLink} className="button">{buttonText}</Link> }
						</div>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default ImageCard;