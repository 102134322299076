import {useState, useEffect} from 'react';

const STATUS = {
	INITIAL: 1,
	1: 'INITIAL',
	LOADING: 2,
	2: 'LOADING',
	LOADED: 3,
	3: 'LOADED',
	FAIL: 4,
	4: 'FAIL',
	SSR: 5,
	5: 'SSR',
}

const isSSR = (typeof window === 'undefined');

const useLiveData = ({url, namespace, model, method, apiParams, type, liveRefresh = true, staticData, graphqlKey}) => {
	const initialState = {
		status: STATUS.INITIAL,
		statusText: STATUS[STATUS.INITIAL],
	}
	if (staticData) {
		let key = graphqlKey;
		if (!!graphqlKey === false && !!type !== false)key = `all${type}`;
		if (staticData[key] !== undefined) {
			initialState.response = {
				data: [],
			};
			staticData[key].edges.forEach(({node}) => initialState.response.data.push({...node}));
		}
	}
	if (isSSR){
		initialState.status = STATUS.SSR;
		initialState.statusText = STATUS[STATUS.SSR];
	}
	const [apiResponse, setApiResponse] = useState(initialState);
	const lang = process.env.LANG||'en';
	const [isActive, setIsActive] = useState(!isSSR);
	if (process.env.LIVE_REFRESH !== undefined){
		liveRefresh = (
			'true'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0 ||
			'yes'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0 ||
			'1'.localeCompare(process.env.LIVE_REFRESH, 'en', {sensitivity: 'base'}) === 0
		);
	}
	const watchVisibility = (evt) => {
		if (!isSSR) {
			setIsActive(document.visibilityState === 'visible');
			if (document.visibilityState === 'visible') {
				updateData({
					visibilityChange: true,
				});
			}
		}
	}
	useEffect(() => {
		if (liveRefresh && !isSSR) {
			document.addEventListener('visibilitychange', watchVisibility);
		}
		return () => {
			if (liveRefresh && !isSSR) {
				document.removeEventListener('visibilitychange', watchVisibility);
			}
		}
	});

	const updateData = (props) => {
		let apiURL = process.env.GATSBY_ZMS_API_URL||'/';
		if (url !== undefined && /https?:/.test(url) === false)apiURL += url;
		if (url !== undefined && /https?:/.test(url) === true)apiURL = url;
		if (model !== undefined) {
			apiURL += `${(namespace !== undefined ? namespace+'/' : '')}${model}/${(method !== undefined ? method : 'all')}/`;
		}
		if (type) {
			apiURL += `${type.split(/(?=[A-Z])/).map(item => item.toLowerCase()).join('/')}/${(method !== undefined ? method : 'all')}/`;
		}
		if (!!apiParams === false)apiParams = {};
		const keys = Object.keys(apiParams);
		let queryString = keys.map(key => `${key}=${apiParams[key]}`).join('&');
		queryString += `${(queryString.length > 0 ? '&' : '')}_lang=${lang}&_joinLang=${lang}`;
		apiURL += (apiURL.indexOf('?') === -1 ? '?' : '&') + queryString;
		setApiResponse({
			status: STATUS.LOADING,
			statusText: STATUS[STATUS.LOADING],
			response: apiResponse.response,
			...props,
		});
		fetch(apiURL)
			.then(response => {
				if (response.status !== 200)throw response.statusText;
				return response.json();
			})
			.then(json => {
				setApiResponse({
					status: STATUS.LOADED,
					statusText: STATUS[STATUS.LOADED],
					response: json,
					...props,
				})
			})
			.catch(err => {
				setApiResponse({
					status: STATUS.FAIL,
					statusText: STATUS[STATUS.FAIL],
					response: apiResponse.response,
					...props,
				})
			});
	}

	if (!isActive)return apiResponse;
	if (apiResponse.status === STATUS.INITIAL) {
		updateData();
	}
	return apiResponse;
}

useLiveData.SSR = STATUS.SSR;
useLiveData.INITIAL = STATUS.INITIAL;
useLiveData.LOADING = STATUS.LOADING;
useLiveData.LOADED = STATUS.LOADED;
useLiveData.FAIL = STATUS.FAIL;

export default useLiveData;