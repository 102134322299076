import * as React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Slider from 'components/slider';

const Gallery = ({slides, slidesPerView, flush}) => {
	return (
		<Slider slides={slidesPerView} navigation={true}>
			{slides.map(slide => {
				return (
					<swiper-slide key={slide.id}>
						<section className="gallery">
							<Container maxWidth="desktop">
								<Grid container>
									<Grid phone={12}>
										<div className="image-container"><img className="slide-image" src={`${slide.image}?width=>1920&format=webp`} alt={slide.title} /></div>
										{slide.description && <p>{slide.description}</p> }
									</Grid>
								</Grid>
							</Container>	
						</section>
					</swiper-slide>
				)
			})}
		</Slider>
	)
}

export default Gallery;