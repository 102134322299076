import * as React from 'react';

import ContentCards from 'components/contentCards';
import useLiveData from 'hooks/useLiveData';
import { useStaticQuery, graphql } from 'gatsby';

const ContentCardProvider = (props) => {
	const data = useStaticQuery(graphql`
		query contentCards {
		  allSmartTagsContentCard {
		    edges {
		      node {
		        id
		        header
		        blocks {
		        	title
		        	description
		        	background
		        }
		        flush
		      }
		    }
		  }
		}
	`);
	const {response} = useLiveData({
		namespace: 'SmartTags',
		model: 'ContentCard',
		method: 'all',
		staticData: data,
		graphqlKey: 'allSmartTagsContentCard',
	});
	if (response.data.length === 0)return null;
	let attr;
	for (let item of response.data) {
		if (item.id === props.id)attr = item;
	}
	if (!attr)return null;
	return <ContentCards {...attr} />
}

export default ContentCardProvider;