import * as React from "react"
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from 'components/header';
import RedirectModal from 'components/redirectModal';
import theme from 'utils/theme';
import SkipNavigationLink from 'components/skipNavigationLink';
import Footer from 'components/footer';
import { MAIN_CONTENT_ID } from '../constants/';
import BodyClassName from 'react-body-classname';

import 'styles/app.scss';

const Layout = ({children}) => {
	const breakpointStyles = {
		'mq-phone': useMediaQuery(theme.breakpoints.only('phone')),
		'mq-tablet': useMediaQuery(theme.breakpoints.only('tablet')),
		'mq-laptop': useMediaQuery(theme.breakpoints.only('laptop')),
		'mq-desktop': useMediaQuery(theme.breakpoints.only('desktop')),
		'mq-phone-up': useMediaQuery(theme.breakpoints.up('phone')),
		'mq-tablet-up': useMediaQuery(theme.breakpoints.up('tablet')),
		'mq-laptop-up': useMediaQuery(theme.breakpoints.up('laptop')),
		'mq-desktop-up': useMediaQuery(theme.breakpoints.up('desktop')),
		'mq-phone-down': useMediaQuery(theme.breakpoints.down('phone')),
		'mq-tablet-down': useMediaQuery(theme.breakpoints.down('tablet')),
		'mq-laptop-down': useMediaQuery(theme.breakpoints.down('laptop')),
		'mq-desktop-down': useMediaQuery(theme.breakpoints.down('desktop')),
	};
	const className = [];
	for (let breakpoint in breakpointStyles) {
		if (breakpointStyles[breakpoint])className.push(breakpoint);
	}
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline>
				<GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
					<BodyClassName className={className.join(' ')}>
						<>
							<SkipNavigationLink />
							<RedirectModal />
							<Header />
							<section id={MAIN_CONTENT_ID} aria-label="Start of content"></section>
							{children}
							<Footer />
						</>
					</BodyClassName>
				</GoogleReCaptchaProvider>
			</CssBaseline>
		</ThemeProvider>
	)
}

export default Layout;