import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/link';

import facebook from 'images/icons/facebook-f.svg';
import linkedin from 'images/icons/linkedin-in.svg';
import xIcon from 'images/icons/icon-x-twitter.svg';
import instagram from 'images/icons/icon-instagram.svg';

const Footer = (props) => {
	const data = useStaticQuery(graphql`
		query footerMenu {
		  allContentMenu (filter: {menuID: {eq: "footer"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const {response: providerResponse} = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'footer',
		},
		staticData: data,
	});
	const links = providerResponse.data[0].pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});
	return (
		<footer>
			<Container maxWidth="desktop">
				<Grid container alignItems="center">
					<Grid phone={12} tablet={7}>
						<nav>
							<ul className="footerNav">
								{links.map((item, index) => <li key={index}><Link href={item.href}>{item.text}</Link></li>)}
							</ul>
						</nav>
					</Grid>
					<Grid phone={12} tablet={5}>
{/*						<p className="newsletter-signup">Be the first to know the latest news and information.</p>
						<Link href="#" className="button light">Subscribe Now</Link><br/>
*/}						<div className="social">
							<Link href="https://www.facebook.com/GoForthInsurance/"><img src={facebook} className="facebook" alt="Facebook (@GoForthInsurance)"/></Link>
							<Link href="https://www.linkedin.com/company/forthinsurance"><img src={linkedin} className="linkedIn" alt="LinkedIn (@ForthInsurance)"/></Link>
							<Link href="https://twitter.com/ForthInsurance"><img src={xIcon} className="X" alt="X (@ForthInsurance)"/></Link>
							<Link href="https://www.instagram.com/forthinsurance"><img src={instagram} className="instagram" alt="Instagram (@ForthInsurance)"/></Link>
						</div>
						<p className="copyright">© 2023 Forth Insurance. All Rights Reserved <Link href="/privacy/">Privacy Notice</Link></p>
					</Grid>
				</Grid>
			</Container>
		</footer>
	)
}

export default Footer;