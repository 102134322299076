import * as React from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import zparse from 'components/zparse';

const ContentCards = ({header, blocks = [], flush = false}) => {
	return (
		<section className={`contentCards ${flush ? 'flush' : ''}`}>
			<Container maxWidth="desktop">
				<Grid container>
					<Grid phone={12}>
						<div className="h2">{header}</div>
					</Grid>
				</Grid>
				<Grid container>
					{blocks.map((item, index) => {
						const style = {};
						if (item.background)style.backgroundImage = `url('${item.background}?format=webp&width=>1200')`;
						return (
							<Grid key={index} phone={12} tablet={6}>
								<div className="contentCard" style={style}>
									<div className="title">{item.title}</div>
									{item.description && zparse(item.description) }
								</div>
							</Grid>
						)
					})}
				</Grid>
			</Container>
		</section>
	)
}

export default ContentCards;