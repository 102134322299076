import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/link';
// import Offcanvas from 'components/offcanvas';
import MobileMenu from 'components/mobileMenu';
import Alert from 'components/alert';

// import logo from 'images/logo.svg';
import logoV2 from 'images/trademark-color-logo.svg'
import menu from 'images/grip-lines.svg';

const Header = (props) => {
	const data = useStaticQuery(graphql`
		query headerMenu {
		  allMainMenu:allContentMenu (filter: {menuID: {eq: "main"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		  allUtilityMenu:allContentMenu (filter: {menuID: {eq: "utility"}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const {response: mainMenu} = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'main',
		},
		graphqlKey: 'allMainMenu',
		staticData: data,
	});
	const {response: utiltyMenu} = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'utility',
		},
		graphqlKey: 'allUtilityMenu',
		staticData: data,
	});
	// const [showOffCanvas, setShowOffCanvas] = React.useState(false);
	const [showMobileMenu, setShowMobileMenu] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const closeMenu = () => {
		// setShowOffCanvas(false);
		setShowMobileMenu(false);
		setAnchorEl(null);
	}

	// const toggleMenu = (evt) => {
	// 	setAnchorEl((showOffCanvas ? null : evt.currentTarget));
	// 	setShowOffCanvas(!showOffCanvas);
	// }
	const toggleMobileMenu = (evt) => {
		setAnchorEl((showMobileMenu ? null : evt.currentTarget));
		setShowMobileMenu(!showMobileMenu);
	}

	const mainLinks = mainMenu.data[0].pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});
	const utilLinks = utiltyMenu.data[0].pages.filter(item => {
		if (item.visible === 'true' || item.visible === true)return true;
		return false;
	});

	return (
		<header>
			<Container maxWidth="desktop">
				<Grid container alignItems="center">
					<Grid phone={6} laptop={2}>
						<Link href="/" className="logo"><img src={logoV2} alt="" /></Link>
					</Grid>
					<Grid phone={6} laptop={10}>
						<nav className="main-nav">
							<ul className="primaryNav">
								{mainLinks.map((item, index) => <li key={index}><Link href={item.href}>{item.text}</Link></li>)}
							</ul>
							<ul className="utilityNav">
								{utilLinks.map((item, index) => <li key={index}><Link href={item.href}>{item.text}</Link></li>)}
							</ul>
							{/*<button className={`menuButton ${showOffCanvas ? 'menu-open' : ''}`} onClick={toggleMenu} ><img src={menu} alt="Additional Menu" /></button>*/}
						</nav>
						<nav className="mobile-nav">
							<button className={`menuButton ${showMobileMenu ? 'menu-open' : ''}`} onClick={toggleMobileMenu} ><img src={menu} alt="Additional Menu" /></button>
						</nav>
					</Grid>
				</Grid>
			</Container>
			{/*<Offcanvas open={showOffCanvas} anchor={anchorEl} onClose={closeMenu}/>*/}
			<MobileMenu open={showMobileMenu} anchor={anchorEl} onClose={closeMenu}/>
			<Alert />
		</header>
	)
}

export default Header;