import React from "react"
import parse, { domToReact } from 'html-react-parser';

import Link from 'components/link';
import ImageCardProvider from 'providers/imageCard';
import QuickLinkProvider from 'providers/quickLink';
import GalleryProvider from 'providers/gallery';
import ContentCardProvider from 'providers/contentCard';

const parserOptions = {
	replace: ({ name, attribs, children }) => {
		switch (name) {
			case "a":
				attribs.className = attribs.class;
				return <Link {...attribs}>{ domToReact(children) }</Link>

			case 'smarttag':
				if (attribs.component !== undefined) {
					const { component } = attribs;
					delete attribs.component;
					for (const key in attribs) {
						if (attribs[key] === 'false')attribs[key] = false;
						if (attribs[key] === 'true')attribs[key] = true;	
					}
					switch (component) {
						case 'ImageCard':
							return (
								<>
									{<ImageCardProvider {...attribs} />}
									{ domToReact(children, parserOptions) }
								</>
							)

						case 'QuickLink':
							return (
								<>
									{<QuickLinkProvider {...attribs} />}
									{ domToReact(children, parserOptions) }
								</>
							)

						case 'Gallery':
							return (
								<>
									{<GalleryProvider {...attribs} />}
									{ domToReact(children, parserOptions) }
								</>
							)

						case 'ContentCard':
							return (
								<>
									{<ContentCardProvider {...attribs} />}
									{ domToReact(children, parserOptions) }
								</>
							)

						default:
							return null;
					}
				}
				return null;

			default:
		}
	}
};

export default function zparse(str, options = parserOptions) {
	if (!str) return "";
	return parse(str, options);
}