import React, { useState, useEffect } from "react"
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import zparse from 'components/zparse';
import close from 'images/xmark.svg';

const AlertBanner = ({id, message}) => {
	const [show, setShow] = useState(true);
	const [contentHeight, setContentHeight] = useState(0);

	const [ref, { height }] = useMeasure();
	
	const handleClose = (evt) => {
		setShow(false);
		window.localStorage.setItem(`alert_${id}`, 0);
	}

	const springProps = useSpring({
		config: {
			duration: 300,
		},
		from: {
			opacity: 1,
			height: contentHeight,
		},
		opacity: (show ? 1 : 0),
		height: (show ? contentHeight : 0),
	});

	useEffect(() => {
		setContentHeight(height);
		window.addEventListener('resize', setContentHeight(height));
		return window.removeEventListener('resize', setContentHeight(height));
	}, [height]);

	return (
		<animated.div style={springProps}>
			<div ref={ref}>
				<section className="alertBanner">
					<Container maxWidth="desktop">
						<Grid container spacing={0}>
							<Grid phone={10}>
								<div className="message">
									{ zparse(message) }
								</div>
							</Grid>
							<Grid phone={2}>
								<div className="closeButton">
									<button onClick={handleClose} className="naked"><img src={close} alt="Close"/></button>
								</div>
							</Grid>
						</Grid>
					</Container>
				</section>
			</div>
		</animated.div>
	)
}

export default AlertBanner;