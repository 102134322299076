import * as React from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'components/link';

import addressCard from 'images/icons/address-card.svg';
import bell from 'images/icons/bell.svg';
import calendar from 'images/icons/calendar-check.svg';
import gear from 'images/icons/gear.svg';
import wallet from 'images/icons/wallet.svg';

const icons = {
	check: calendar,
	bell: bell,
	businesscard: addressCard,
	gear: gear,
	wallet: wallet,
}

const QuickLinks = ({header, background, links = [], flush = false}) => {
	return (
		<section className={`quickLinks ${background ? background : ''} ${flush ? 'flush' : ''}`}>
			<Container maxWidth="desktop">
				<Grid container>
					<Grid phone={12}>
						<div className="h2 center">{header}</div>
					</Grid>
				</Grid>
				<Grid container columns={{phone: 4, tablet: 8, laptop: 12}}>
					{links.map((item, index) => {
						return (
							<Grid key={index} phone={4}>
								<div className="quickLink">
									<div className="icon"><img src={icons[item.icon]} alt={item.title} /></div>
									<div className="title">{item.title}</div>
									{item.description && <p>{item.description}</p> }
									{ item.buttonLink && <Link href={item.buttonLink} className="button">{item.buttonText}</Link> }
								</div>
							</Grid>
						)
					})}
				</Grid>
			</Container>
		</section>
	)
}

export default QuickLinks;