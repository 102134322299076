import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import useLiveData from 'hooks/useLiveData';
import Dropdown from 'components/dropdown';

const MobileMenu = (props) => {
	const data = useStaticQuery(graphql`
		query mobileMenu {
		  allContentMenu (filter: {menuID: {in: ["main", "utility"]}}) {
		    edges {
		      node {
		        pages
		      }
		    }
		  }
		}
	`);
	const {response: providerResponse} = useLiveData({
		type: 'ContentMenu',
		method: 'all',
		apiParams: {
			menuID: 'main,utility',
		},
		staticData: data,
	});
	const dupes = [];
	const links = [];
	providerResponse.data.forEach(item => {
		item.pages.forEach(link => {
			if (link.visible !== 'true' && link.visible !== true)return null;
			if (dupes.indexOf(link.href) !== -1)return null;
			links.push(link);
		});
	});
	return <Dropdown
		links={links}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
}

export default MobileMenu;