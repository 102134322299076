import * as React from 'react';

import Gallery from 'components/gallery';
import useLiveData from 'hooks/useLiveData';
import { useStaticQuery, graphql } from 'gatsby';

const GalleryProvider = (props) => {
	const data = useStaticQuery(graphql`
		query gallery {
		  allSmartTagsGallery(sort: {Category: {object: {Slide: {object: {sort: ASC}}}}}) {
		    edges {
		      node {
		        id
		        flush
		        slidesPerView
		        Category {
		          object {
		          	id
		            Slide {
		              object {
		                title
		                image
		                description
		              }
		            }
		          }
		        }
		      }
		    }
		  }
		}
	`);
	const { response: smartTagResponse, status: smartTagStatus } = useLiveData({
		namespace: 'SmartTags',
		model: 'Gallery',
		method: 'get',
		apiParams: {
			id: props.id,
		}
	});
	const { response: galleryResponse, status: galleryStatus } = useLiveData({
		namespace: 'Masthead',
		model: 'Slide',
		method: 'all',
		apiParams: {
			_sort: 'sort',
		},
	});
	let attr = {};
	if (data.allSmartTagsGallery) {
		for (let { node } of data.allSmartTagsGallery.edges) {
			if (node.id === props.id){
				attr.slidesPerView = node.slidesPerView;
				attr.flush = node.flush;
				attr.slides = [];
				if (node.Category) {
					for (let {object} of node.Category) {
						if (object.Slide) {
							for (let slide of object.Slide) {
								attr.slides.push(slide.object);
							}
						}
					}
				}

			}
		}
	}
	if (smartTagResponse && smartTagStatus === useLiveData.LOADED) {
		if (smartTagResponse.data.length === 0)return null;
		if (galleryResponse && galleryStatus === useLiveData.LOADED && galleryResponse.data.length === 0)return null;
		if (galleryStatus === useLiveData.LOADED) {
			const tag = smartTagResponse.data[0];
			attr.slidesPerView = tag.slidesPerView;
			attr.flush = tag.flush;
			attr.slides = [];
			if (tag.Category && tag.Category.length > 0) {
				const categoryID = tag.Category[0].object.id;
				attr.slides = galleryResponse.data.map(item => {
					if (!!item.Category === false)return null;
					if (item.Category.length === 0)return null;
					if (item.Category[0].object.id !== categoryID)return null;
					return item;
				}).filter(item => item);
			}
		}
	}
	return <Gallery {...attr}/>;
}

export default GalleryProvider;